import { removeCookie } from '@/utils/cookie';

export const handleError = (self, err, withOut = false) => {
    let code = false;
    let message;

    if (err.response.data) {
        if (err.response.data.errors && err.response.data.errors.length > 1) {
            message = err.response.data.errors[0].msg;
        } else if (
            err.response.data.errcode &&
            self.$i18n.t('error.' + err.response.data.errcode)
        ) {
            message = self.$i18n.t('error.' + err.response.data.errcode);
            code = err.response.data.errcode;
        } else {
            message = err.message;
        }
    }
    //console.log(code);
    if (
        code &&
        ['tokeninvalid', 'tokeninvalid', 'tokenusernotfound'].indexOf(code) > -1
    ) {
        removeCookie('user-token');
        self.$router.push('login');
    } else {
        if (!code || (code && code != withOut)) {
            self.$notify({ type: 'danger', message });
        }

        return code;
    }
};
